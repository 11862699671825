//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";


import { minValue, maxValue, required} from "vuelidate/lib/validators";
import axios from "axios";
import Paginator from "@/components/Paginator";
import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
    Paginator,
    Layout,
    PageHeader
  },
  data() {
    return {
      addBonusPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      submitted: false,
      title: this.$t('menuitems.management.list.clients'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.clients'),
          active: true
        }
      ],
      data: [],
      searchString: '',
      sumPoint: 0,
      addBonusUserId: 0,
      searchStrTimeout: false,
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
    };
  },
  validations: {
    sumPoint: { required, minValue: minValue(1), maxValue: maxValue(5000) }
  },
  computed: {

  },
  watch: {
    searchString: function(){
      clearTimeout(this.searchStrTimeout);

      this.searchStrTimeout = setTimeout(() => {
        this.getData();
      }, 2000);
    },
  },
  methods: {
    submitAddBonus(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.sumPoint.$touch();
      if(!this.$v.sumPoint.$invalid){
        this.$store.dispatch("startPreloader");
        let formData = new FormData();
        formData.append("sum", this.sumPoint);
        formData.append("user_id", this.addBonusUserId);
        axios
          .post(`/v1/users/set-copix-bonus`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
              this.closeAddBonusPopup();
              this.getData();
            }
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
      }
    },
    closeAddBonusPopup(){
      this.sumPoint = 0;
      this.addBonusPopup.show = false;
    },
    showAddBonusPopup(userId){
      this.addBonusUserId = userId;
      this.addBonusPopup.show = true;
    },
    editUserShow(obj){
      this.editUser = obj;
      this.editUserPopup.show = true;
    },
    closeEditUserPopup(){
      this.editUserPopup.show = false;
    },
    getData() {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);
      formData.append("search_str", this.searchString);
      axios
          .post(`/v1/users/get-copix`, formData)
          .then(resp => {
            this.data = resp.data.data;
            this.pagesCountPaginator = resp.data.pages;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    },
    setPage(val) {
      this.thisPage = val;
      this.getData();
    }
  },
  created() {
    this.getData();
  }
};
